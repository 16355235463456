const rmClass = (el, class_name) => {

    if (el.classList) {

        el.classList.remove(class_name);

    } else {

        el.className = el.className.replace(new RegExp('(^|\\b)' + class_name.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');

    }

};

const addClass = (el, class_name) => {

    if (el.classList) {

        el.classList.add(class_name);

    } else {

        el.className += ' ' + class_name;

    }

};

const toogleClass = (el, class_name) => {

    if (el.classList) {

        el.classList.toggle(class_name);

    } else {

        let classes = el.className.split(' ');
        let existingIndex = classes.indexOf(class_name);

        if (existingIndex >= 0) {

            classes.splice(existingIndex, 1);

        } else {

            classes.push(class_name);

        }

        el.className = classes.join(' ');

    }

};

const hasClass = (el, class_name) => {

    if (el.classList) {

        return el.classList.contains(class_name);

    } else {

        return new RegExp('(^| )' + class_name + '( |$)', 'gi').test(el.className);

    }

};

const throttle = (func, interval) => {

    let lastCall = 0;

    return function () {

        let now = Date.now();

        if (lastCall + interval < now) {

            lastCall = now;
            return func.apply(this, arguments);

        }

    };

}

const ajaxQuery = (type, url, data) => {

    return new Promise((resolve, reject) => {

        var request = new XMLHttpRequest();
        request.open(type, url, true);

        request.onload = function () {

            if (request.status >= 200 && request.status < 400) {
                var resp = request.responseText;

                resolve(resp);

            } else {
                reject(request);
            }

        };


        request.onerror = function () {
        };

        request.send(data);

    });

};