
"use strict";



window.onload = function() {
    adjustHeight();
};

window.onresize = function() {

}



