const equalHeight = (items, applyto) => {

    let $nodelist = document.querySelectorAll(items);
    if ($nodelist.length < 1) { return false; }

    let $items = applyHeight([...$nodelist]); 

    let all_heights = $items.map((item) => item.clientHeight);

    let highest = Math.max.apply(null, all_heights) + "px";

    if (typeof applyto !== "undefined") {
        applyHeight([...applyto], highest);
    }
    else {
        applyHeight($items, highest);
    }

};

const applyHeight = (arr, height = 'auto') => arr.map((el) => {
    el.style.height = height;
    return el;
});


const adjustHeight = throttle(() => {

        setTimeout(() => {

                equalHeight(".list-services li");
        equalHeight(".list-icons li");

            }, 300);

    }, 200);