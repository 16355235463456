const backToTop = () => {

    const id = "#back_to_top";

    if (jQuery(id).length < 1) { return false; }

    const scrollTopBtn = jQuery(id);
    const class_name = "active";

    let offset = 300;
    let scrollTopDuration = 700;
    let lastScrollTop = 0;

    jQuery(window).scroll(function() {

        let st = jQuery(this).scrollTop();

        if (jQuery(this).scrollTop() > offset) {

            if (st >= lastScrollTop) {

                scrollTopBtn.removeClass(class_name);

            } else {

                scrollTopBtn.addClass(class_name);

            }

            lastScrollTop = st;

        } else {

            scrollTopBtn.removeClass(class_name);
            lastScrollTop = 0;

        }
    });

    jQuery(document).on('click', id, function(e) {

        e.preventDefault();
        jQuery('body, html').animate({ scrollTop: 0 }, scrollTopDuration);

    });

};
